import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`

const Player = styled.div`
  height: 48px;
  border-radius: 24px;
  border: 2px solid ${props => props.theme.color.primaryLight};
  position: relative;
  flex-grow: 1;
`

const PlayButton = styled.button`
  background: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -2px;
  outline: none;
  transition-duration: 0.1s;
  transform: translateY(-5px);

  &:hover {
    transform: translateY(-7px);

    &::before {
      transform: translateY(7px);
    }
  }

  &:active {
    transform: translateY(0px);

    &::before {
      transform: translateY(0px);
    }
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    z-index: -1;
    background-color: ${props => props.theme.color.primaryLight};
    transform: translateY(5px);
    transition-duration: 0.1s;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 0;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border: 2px solid #000;
    border-radius: 50%;
  }
`

const PlayTime = styled.div`
  position: absolute;
  left: 58px;
  top: 4px;
`

const PlayTimeCurrent = styled.span`
  font-size: 17px;
  font-weight: bold;
`

const PlayTimeTotal = styled.span`
  font-size: 13px;
  color: ${props => props.theme.color.primaryTextDark};
`

const TimelineProgress = styled.div.attrs<{ progress: number }>(
  ({ progress }) => ({
    style: {
      width: progress + "%",
    },
  })
)<{ progress: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  border-radius: 2px;
`

const TimelineMarker = styled.div.attrs<{ progress: number }>(
  ({ progress }) => ({
    style: {
      left: progress + "%",
    },
  })
)<{ progress: number }>`
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;

  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #000;
  transform: translate(-50%, -50%);
  transition: transform 0.1s;
  cursor: pointer;

  &:active,
  &:hover:active {
    background-color: ${props => props.theme.color.primary};
    border-color: #000;
    transform: translate(-50%, -50%) scale(1.8) !important;
  }
`

const Timeline = styled.div`
  position: absolute;
  left: 58px;
  bottom: 10px;
  right: 16px;
  height: 4px;
  border-radius: 2px;
  cursor: pointer;
  background-color: ${props => props.theme.color.primaryLight};

  &:hover ${TimelineMarker} {
    border-width: 2px;
    transform: translate(-50%, -50%) scale(1.5);
  }
`

const Title = styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  top: 4px;
  right: 16px;
  max-width: calc(100% - 170px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Styled = {
  Title,
  Timeline,
  TimelineProgress,
  TimelineMarker,
  PlayTime,
  PlayTimeCurrent,
  PlayTimeTotal,
  Player,
  PlayButton,
  Wrapper,
}

export default Styled
