import styled from "styled-components"

export type RowProps = {
  alignItems?: "flex-start" | "center" | "flex-end"
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "stretch"
    | "space-between"
  flexWrap?: boolean
  columns?: boolean
  reverse?: boolean
}

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "space-between"};
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
  ${props => props.flexWrap && `flex-wrap: wrap;`}
  ${props => props.columns && "margin: 0 -16px;"}
  ${props => props.reverse && "flex-direction: row-reverse;"}
`

export default Row
