import styled from "styled-components"

const IconButton = styled.a<{ noMargin?: boolean }>`
  color: #808080;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => (props.noMargin ? "" : "12px")};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.primaryLight};
    color: #000;
  }
`

export default IconButton
