import styled from "styled-components"
import React from "react"
import Icon from "components/Icon"

const Wrapper = styled.div<{ white?: boolean }>`
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  border-radius: 50%;
  position: relative;
  z-index: 1;
  margin: 0 12px 0 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: -1;
    border-radius: 50%;
    background-color: ${props =>
      props.white ? "#fff" : props.theme.color.primaryLight};
    transform: translateY(3px);
  }

  &::after {
    display: block;
    content: "";
    border: 1px solid
      ${props => (props.white ? props.theme.color.primary : "#000")};
    z-index: 1;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
`

export type WIconProps = {
  icon: string
  white?: boolean
}

const WIcon = ({ icon, white }: WIconProps) => {
  return (
    <Wrapper white={white}>
      <Icon icon={icon} />
    </Wrapper>
  )
}

export default WIcon
