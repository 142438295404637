import React, { Component, useEffect, useRef, useState } from "react"
import { downloadOutline, pauseOutline, playOutline } from "ionicons/icons"
import Styled from "./AudioPlayer.styled"
import Icon from "components/Icon"
import config from "config/config"
import IconButton from "components/IconButton"
import { Subscription } from "rxjs"

export type AudioPlayerProps = {
  src: string
  title: string
  downloadButton?: boolean
}

interface AudioPlayerContextInterface {
  value: boolean
  toggle: () => void
}

interface WithAudioPlayerContextInterface {
  audioPlayerContext: AudioPlayerContextInterface
}

export const AudioPlayerContext = React.createContext({
  value: true,
  toggle: () => {
    return
  },
})

export class AudioPlayerContextProvider extends Component<
  {},
  AudioPlayerContextInterface
> {
  private subscription!: Subscription

  constructor(props: {}) {
    super(props)
    this.state = {
      value: true,
      toggle: () => this.setState({ value: !this.state.value }),
    }
  }

  render() {
    return (
      <AudioPlayerContext.Provider value={this.state}>
        {this.props.children}
      </AudioPlayerContext.Provider>
    )
  }
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

export const withAudioPlayerContext = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof WithAudioPlayerContextInterface>> => props => (
  <AudioPlayerContext.Consumer>
    {value => <Component {...(props as P)} audioPlayerContext={value} />}
  </AudioPlayerContext.Consumer>
)

const AudioPlayer = ({
  src,
  title,
  downloadButton,
  audioPlayerContext,
}: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const timelineRef = useRef<HTMLDivElement>(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [totalDuration, setTotalDuration] = useState(0)
  const [currentPlaytime, setCurrentPlaytime] = useState(0)
  const [toggler, setToggler] = useState(false)
  const handleLoadedMetadata = () => {
    setTotalDuration(Math.floor(audioRef.current!.duration))
  }
  const handleTimeUpdate = () => {
    setCurrentPlaytime(Math.floor(audioRef.current!.currentTime))
  }

  useEffect(() => {
    const audio = audioRef.current
    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetadata)
      audio.addEventListener("timeupdate", handleTimeUpdate)
      return () => {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata)
        audio.removeEventListener("timeupdate", handleTimeUpdate)
      }
    }
  }, [audioRef])

  useEffect(() => {
    if (audioRef.current && !toggler) {
      audioRef.current.pause()
      setIsPlaying(false)
    } else {
      setToggler(false)
    }
  }, [audioPlayerContext])

  const togglePlay = () => {
    const audio = audioRef.current
    if (audio) {
      if (audio.paused) {
        setToggler(true)
        audioPlayerContext.toggle()
        setIsPlaying(true)
        audio.play()
      } else {
        setToggler(false)
        setIsPlaying(false)
        audio.pause()
      }
    }
  }
  const getTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)

    return minutes + ":" + (seconds < 10 ? "0" + seconds : seconds)
  }

  const handleTimelineClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    const timelineBoundingRect = timelineRef.current!.getBoundingClientRect()
    const pos = e.clientX - timelineBoundingRect.left
    const newPlaytime = Math.max(
      0,
      Math.min(
        totalDuration,
        Math.floor((pos / timelineBoundingRect.width) * totalDuration)
      )
    )
    setCurrentPlaytime(newPlaytime)
    audioRef.current!.currentTime = newPlaytime
  }

  const handleMarkerDrag = (e: MouseEvent) => {
    const timelineBoundingRect = timelineRef.current!.getBoundingClientRect()
    const pos = e.clientX - timelineBoundingRect.left
    const newPlaytime = Math.max(
      0,
      Math.min(
        totalDuration,
        Math.floor((pos / timelineBoundingRect.width) * totalDuration)
      )
    )
    setCurrentPlaytime(newPlaytime)
    audioRef.current!.currentTime = newPlaytime
  }

  const handleMarkerMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    const clearListeners = () => {
      document.removeEventListener("mousemove", handleMarkerDrag, false)
      document.removeEventListener("mouseup", clearListeners, false)
    }

    document.addEventListener("mousemove", handleMarkerDrag, false)
    document.addEventListener("mouseup", clearListeners, false)
  }

  return (
    <Styled.Wrapper>
      <Styled.Player>
        <audio ref={audioRef} src={config.uploadPrefix + src} />
        <Styled.PlayButton onClick={togglePlay}>
          <Icon icon={isPlaying ? pauseOutline : playOutline} />
        </Styled.PlayButton>
        <Styled.PlayTime>
          <Styled.PlayTimeCurrent>
            {getTime(currentPlaytime)}
          </Styled.PlayTimeCurrent>
          <Styled.PlayTimeTotal>
            / {getTime(totalDuration)}
          </Styled.PlayTimeTotal>
        </Styled.PlayTime>
        <Styled.Timeline
          ref={timelineRef}
          onMouseDown={handleMarkerMouseDown}
          onClick={handleTimelineClick}
        >
          <Styled.TimelineProgress
            progress={(currentPlaytime * 100) / totalDuration}
          />
          <Styled.TimelineMarker
            onMouseDown={handleMarkerMouseDown}
            progress={(currentPlaytime * 100) / totalDuration}
          />
        </Styled.Timeline>
        <Styled.Title>{title}</Styled.Title>
      </Styled.Player>
      {downloadButton && (
        <IconButton href={src}>
          <Icon icon={downloadOutline} />
        </IconButton>
      )}
    </Styled.Wrapper>
  )
}

export default withAudioPlayerContext(AudioPlayer)
